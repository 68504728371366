import logo from "./images/logo-new.png"

function Footer(){

    const currentYear = new Date().getFullYear();

    return(
 <footer>
<div className="relative  overflow-hidden bg-sky-950 py-16 sm:py-24 lg:py-30">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-0 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
          <div className="flex lg:flex-1 flex-col pt-12">
              <a href="#" className="-m-1.5 p-1.5 mx-auto">
                <img className="h-20 w-auto" src={logo} alt="ExpanseProductsLogo" />
              </a>
              <p className="text-3xl font-bold pt-4 text-white mx-auto pt-12">
                Expanse Products Limited
              </p>
              <span className="text-3xl font-bold pt-4 text-white mx-auto"></span>
            </div>
            <p></p>
        
            
          </div>
          <dl className="grid grid-cols-1 mt-10 gap-x-8 gap-y-10 justify-center sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start space-y-4">
            
              <dt className="mt-1 mb-2 font-semibold text-2xl text-white">Contact</dt>
                <dd className="mt-2 leading-7 text-gray-100 text-1xl font-bold mb-2">
                  Call Us At:
                </dd>
                <dd className='text-gray-400 font-bold mb-1'>0721 576 598</dd>
                <dd className='text-gray-400 font-bold '>0722 437 210</dd>
                <br></br>
                <dd className="mt-6 pt-6 mb-2 leading-7 text-gray-100 font-bold">
                  Email Us At:
                </dd>
                <dd className='text-gray-400 font-bold'><a href='mailto:expanseproductsltd@gmail.com'>expanseproductsltd@gmail.com</a></dd>
            </div>
            <div className="flex flex-col items-start">
         
              <dt className="mt-1 mb-2 font-semibold text-2xl text-white">Location</dt>
              <dd className="mt-2 leading-7 text-gray-100 text-1xl font-bold mb-2">
                Visit Our Office:
              </dd>
              <dd className='text-gray-400 font-bold mb-1'>Uniafric House,Koinange street</dd>
              <dd className="mt-6 pt-8 mb-2 leading-7 text-gray-100 font-bold">
                Postal Address:
              </dd>
              <dd className='text-gray-400 font-bold'>P.O BOX, 7544-00100</dd>
            </div>
          </dl>
        </div>
      </div>
      
    </div>
    <div className='sm:h-16 bg-green-600 pt-4 md:h-14'>
      <p className="justify-between text-lg font-bold text-gray-900 text-center">
          <strong className="font-semibold p-2"> © {currentYear} Expanse Products Ltd - Leading Sawdust Briquettes Manufacturer in Kenya</strong>
        </p>
      </div>
    </footer>
    )
}
export default Footer;