import galleryImage1 from "../../widgets/images/expanse1.jpg"
import galleryImage2 from "../../widgets/images/K4.jpg"
import galleryImage3 from "../../widgets/images/pell2.jpeg"
import galleryImage4 from "../../widgets/images/saw.jpeg"
import galleryImage5 from "../../widgets/images/Exp5.jpeg"
import galleryImage6 from "../../widgets/images/expanse.jpeg"
import galleryImage7 from "../../widgets/images/pell.jpeg"




function Gallery(){
    return(
      <div id="gallery" className="bg-white dark:bg-gray-800  h-3/4 py-6 px-4 sm:py-8 lg:py-12 lg:px-20">
    <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div className="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
            <div className="flex flex-col gap-12">
                <h2 className="text-4xl font-bold leading-7 tracking-tight pb-4 text-indigo-600 pl-4">Gallery</h2>

                <p className="hidden max-w-screen-sm text-gray-600 dark:text-gray-300 md:block">
                Explore Our Eco-Friendly Sawdust Briquette Gallery.
                </p>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
            {/* <!-- image - start --> */}
            <a href="#"
                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                <img src={galleryImage4} className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            {/* <!-- image - end --> */}

            {/* <!-- image - start --> */}
            <a href="#"
                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                <img src={galleryImage1} className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            {/* <!-- image - end --> */}

            {/* <!-- image - start --> */}
            <a href="#"
              className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 md:h-80">
                <img src={galleryImage7} loading="lazy" alt="sawdust briquette" className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            {/* <!-- image - end --> */}

            {/* <!-- image - start --> */}
            <a href="#"
                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                <img src={galleryImage3} className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            <a href="#"
                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                <img src={galleryImage2} className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            <a href="#"
                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80 md:col-span-2">
                <img src={galleryImage5} className="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110"/>

                <div
                    className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                </div>

            </a>
            {/* <!-- image - end --> */}
        </div>
    </div>
</div>
  )
}
export default Gallery;