

function ContactSupport({isOpen,setOpen}){
    return(
        <>
            {isOpen &&(
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      
        <div className="mt-3 text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg
              className="h-6 w-6 text-indigo-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-800">
              Thank you for contacting Us!
              <br/>
              We are currently experiencing some technical issues.
              It will be resolved in a few
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={() => setOpen(false)}
              id="ok-btn"
              className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              OK
            </button>
          </div>
        </div>
    </div>
            )}
        </>
    )
}
export default ContactSupport;