const stats = [


    { id: 1, name: 'Become a regional champion for innovative, high-quality, and environmentally friendly biomass briquettes.', value: 'Vision' },
    { id: 2, name: '', value: '' },
    { id: 3, name: 'Develop, manufacture, and supply high-quality sawdust briquettes solutions for industrial and domestic purposes, that are environmentally clean for the East African Markets.', value: 'Mision' },
  ]

function Cards(){
    return(
  <>
    <div className="bg-gray-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base  font-semibold leading-7 text-gray-600">{stat.name}</dt>
              
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 pb-10 sm:text-5xl">
                <u>{stat.value}</u>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
        </>
    )
}

export default Cards;