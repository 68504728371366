import aboutImage from "../../widgets/images/pr4.jpg";
import { motion } from "framer-motion";

function About() {
  const inViewAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 25,
    },
  };

  return (
    <div
      className="overflow-hidden bg-cyan-950 rounded-lg m-4 py-8 "
      id="about"
    >
      <div className="mx-auto max-w-7xl px-6 md:px-8">
        <div className="mx-auto flex flex-col lg:flex-row md:space-x-4">
          <div className="lg:pr-6">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900"></p>
              <motion.h2
                initial="hidden"
                whileInView="visible"
                variants={inViewAnimation}
              >
                <h2 className="text-4xl font-bold leading-7 tracking-tight pb-4 text-indigo-600 pl-4">
                  About Us
                </h2>
              </motion.h2>
              <p className="mt-6 text-lg leading-8 text-white">
              Expanse Products Limited, based in Nairobi, Kenya, 
              is a pioneer in manufacturing high-quality sawdust briquettes.
              We are dedicated to providing an eco-friendly alternative energy source.
              </p>
              <br />
              <p className="mt-6 text-lg leading-8 text-gray-100">
                We use 100% sawdust biomass to manufacture high quality biomass
                briquette and pellets. Our raw material is dry sawdust ONLY .We
                are able to achieve a maximum moisture content of 6% to 8% at
                the time of packaging.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-100">
                Our current briquette production capacity is 20 tons per day,
                which was increased to 30 tons per day by the end of 2022.
              </p>
            </div>
          </div>
          <div className="mx-0 px-4 mt-10 md:mx-auto  lg:pt-12">
          <img
            src={aboutImage}
            alt="Product screenshot"
            className="w-[20rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:w-[27rem] -ml-4  lg:w-[37rem]"
            width={2432}
            height={1442}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
