import Hero from "./pages/hero";
import {Route,Routes,BrowserRouter as Router } from "react-router-dom";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div>
     <Router>
  
        <Routes>
         <Route path="/" element={<Hero />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
