import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion';

const features = [


  {
    name: 'Environment Friendly',
    description:
      'Briquettes are cleaner and greener source of energy.They produce little or no smoke, soot, or carbon deposits when they burn,and no harmful gases or chemicals are emitted in their burning process.',
    icon: CloudArrowUpIcon,
  },
  
  {
    name: 'Cost Saving',
    description:
      'Our customers save an average of 40% compared to firewood and charcoal!Because briquettes are cheaper than other energy sources like fuel oil, propane and natural gas.',
    icon: ArrowPathIcon,
  },
  
  {
    name: 'Reduce deforestation',
    description:
      'The use of briquettes also has the potential to preserve forests.Approximately 17 trees can be saved per ton of Expanse Briquettes.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Creating Job Market',
    description:
      'With the establishment of our company, we have employed several youths training them and equipping them with the right set of skills.Hence, changing the narrative of many jobless youths in Kenya, by creating year-round jobs.',
    icon: LockClosedIcon,
  }
]

function Impact(){

  const inViewAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 25,
    },
  };

    return(
    <div className="bg-white py-32 sm:py-32" id="impact">
      <div className="mx-auto max-w-20xl px-10 lg:px-14">
        <div className="mx-auto max-w-10xl lg:text-center">
        <motion.h2
                initial="hidden"
                whileInView="visible"
                variants={inViewAnimation}
              >
          <h2 className="text-5xl font-bold leading-7 text-indigo-600 pb-4 sm:text-4xl">Impact</h2>

              </motion.h2>
          <p className="mt-2 text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          Positive Environmental Impact of Our Sawdust Briquettes: 

          </p>
          <p className="mt-8 text-1xl font-normal leading-8 text-gray-600">
          Driving a Paradigm Shift towards Sustainability:<br></br> Igniting Positive Environmental Transformation with Sawdust Briquettes for Clean Energy, Waste Reduction, and Carbon Neutrality</p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-10 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-2xl font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-lg leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    )
}
export default Impact;