import Institution from "./institution";
import { motion } from 'framer-motion';


import {
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

const posts = [
  {
    id: 1,
    title: "Boost your conversion rate",
    href: "#",
    icon: FingerPrintIcon,
    description:
      "Our briquettes are made entirely from locally sourced sawdust waste and specially crafted for industrial boilers.",
  },
  {
    id: 2,
    title: "Boost your conversion rate",
    href: "#",
    icon: CloudArrowUpIcon,
    description:
      "We care about your broilers durability and sustainability, hence, we use natural binding agent for super strong briquettes.",
  },
  {
    id: 3,
    title: "Boost your conversion rate",
    href: "#",
    icon: LockClosedIcon,
    description:
      "As no chemicals or additional additives are used, our sawdust briquettes ensure that your broilers run efficiently.",
  },
  // More posts...
];

function Patner() {

  const inViewAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: 25,
    },
  };

  return (
    <>
      <Institution />
      <div className="bg-white py-10 ">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <motion.h2
              className="font-poppins font-semibold text-3xl md:text-4xl xl:text-5xl text-white"
              initial="hidden"
              whileInView="visible"
              variants={inViewAnimation}
            >
              <h2 className="text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-start">
                Why Choose Us
              </h2>
            </motion.h2>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:mt-16 py-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="flex max-w-xl flex-col items-start"
              >
                <div className="relative flex items-center gap-x-4 mb-8">
                  <div className="absolute left-0 top-0 flex h-8 w-8 items-center mb-6 justify-center rounded-lg bg-indigo-600">
                    <post.icon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="group relative">
                  <p className="mt-5 line-clamp-4 text-lg font-semibold leading-6 text-gray-900">
                    {post.description}
                  </p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default Patner;
