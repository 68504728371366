import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion';

const companies = [


  {
    name: 'learning institutions',
    description:
      'To provide clean and pollution free fuel for their efficient and conducive cooking environment.',
    icon: CloudArrowUpIcon,
  },
  
  {
    name: 'Industrial companies',
    description:
      'To provide clean and efficient fuel for their boilers as part of basic foundation to their production system.',
    icon: ArrowPathIcon,
  },
  
  {
    name: 'Various institutions',
    description:
      'Companies that produce wood sawdust as a byproduct and use it as our raw material, thereby making our contribution towards environment conservation, which is one of our core values.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Domestic Users',
    description:
      'For the provision of clean fuel for indoor use with minimum pollution and efficient and effective cooking environment.',
    icon: LockClosedIcon,
  }
]


function Institution(){

  const inViewAnimation = {
    visible: {
      opacity: 1,
      transition: { duration: 1 },
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 25,
    },
  };

    return(
        <div className="bg-sky-950 w-full py-32 sm:py-32 pt-20" id="work">
        <div className="mx-auto max-w-20xl px-10 lg:px-14">
          <div className="mx-auto max-w-10xl lg:text-center">
            <motion.h2
              className=" md:mb-8 mb-5 font-poppins font-semibold text-3xl md:text-4xl xl:text-5xl text-white"
              initial="hidden"
              whileInView="visible"
              variants={inViewAnimation}
            >
            <h2 className="text-5xl font-bold tracking-tight text-indigo-600 sm:text-4xl">We Work with</h2>
            </motion.h2>
            <p className="mt-2 text-1xl font-semibold tracking-tight text-gray-100 sm:text-4xl">
            Partner with Excellence , Ignite Success: 
  
            </p>
            <p className="mt-8 text-3xl font-normal leading-8 text-gray-100">
            </p>And Unleash the Power of Collaboration with Us
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-10 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {companies.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-2xl font-semibold leading-7 text-gray-100">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-lg leading-7 text-gray-200">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
}
export default Institution;